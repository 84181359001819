/**
 * @summary checks if input is a valid number
 * @param { unknown } input
 * @returns {boolean}
 */
export default function isValidNumber(input) {
  return (
    input !== undefined &&
    input !== null &&
    !Number.isNaN(parseInt(input?.toString(), 10)) &&
    typeof parseInt(input?.toString(), 10) === "number"
  );
}
