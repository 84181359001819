<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
      <KPI
        label="Total campaigns"
        :value="getCampaigns.length"
        title="Total amount of campaigns netwrk agency campaigns."
      />

      <KPI
        label="Total campaign views"
        :value="totalCampaignStats.totalViews"
        title="Total amount of views from shown campaigns."
      />

      <KPI
        label="Total campaign clicks"
        :value="totalCampaignStats.totalClicks"
        title="Total amount of clicks from shown campaigns."
      />

      <KPI label="Total campaign CTR" :value="totalCampaignStats.totalCtr" title="CTR from shown campaigns." unit="%" />

      <KPI
        label="Average streamer campaigns"
        :value="averageUserCampaigns"
        title="Average amount of campaigns streamers are part of."
      />

      <KPI
        label="Median streamer campaigns"
        :value="medianUserCampaigns"
        title="Median of campaigns streamers are part of."
      />
    </div>

    <Title size="medium" :title="`Redirect domains`" class="mb-4" />

    <div v-for="domain of domainStats" v-show="domainStats && domainStats.length" :key="domain.name">
      <Title size="small" :title="`Domain - ${domain.name.trim()}`" class="mb-4" />

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
        <KPI
          label="Total CTR"
          :value="domain && domain.totalCtr ? parseFloat(domain.totalCtr.toFixed(2)) : 0"
          title="CTR from campaigns with this domain. ((click / views) * 100)"
          unit="%"
        />

        <KPI
          label="Total clicks"
          :value="domain.totalClicks || 0"
          title="Total amount of clicks from campaigns with this domain."
        />

        <KPI label="Total views" :value="domain.totalViews || 0" title="Total views from campaigns with this domain." />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { KPI, Title } from "cavea-design-system";
import isValidNumber from "@/lib/isValidNumber";

export default {
  name: "AdminCampaigns",

  metaInfo: {
    title: "Campaign Statistics",
  },

  components: {
    KPI,
    Title,
  },

  data() {
    return {
      domainStats: [],

      averageUserCampaigns: 0,
      medianUserCampaigns: 0,
    };
  },

  computed: {
    ...mapGetters(["getCampaigns"]),

    totalCampaignStats() {
      const statistics = {
        totalViews: 0,
        totalClicks: 0,
        totalCtr: 0,
      };

      if (this.domainStats?.length) {
        for (let i = 0; i < this.domainStats?.length; i += 1) {
          if (isValidNumber(this.domainStats[i].totalViews)) {
            statistics.totalViews += parseInt(this.domainStats[i].totalViews, 10);
          }

          if (isValidNumber(this.domainStats[i].totalClicks)) {
            statistics.totalClicks += parseInt(this.domainStats[i].totalClicks, 10);
          }
        }
      }

      if (statistics.totalViews && statistics.totalClicks) {
        statistics.totalCtr = parseFloat(((statistics.totalClicks / statistics.totalViews) * 100).toFixed(2));
      }

      return statistics;
    },
  },

  created() {
    this.fetchDomainStatistics();
    this.fetchUserCampaignMetrics();
  },

  methods: {
    async fetchDomainStatistics() {
      this.domainStats = await axios
        .get(`${process.env.VUE_APP_API_URL}/admin/stats/domains`)
        .then((res) => res?.data?.domains ?? [])
        .catch((error) => {
          console.error("Error getting domain stats", error);
          return error?.response?.data?.domains ?? [];
        });
    },

    async fetchUserCampaignMetrics() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/ad-campaigns/creators/metrics`)
        .then((res) => {
          this.averageUserCampaigns = res?.data?.averageUserCampaigns ?? null;
          this.medianUserCampaigns = res?.data?.medianUserCampaigns ?? null;
        })
        .catch((error) => {
          this.averageUserCampaigns = error?.response?.data?.averageUserCampaigns ?? null;
          this.medianUserCampaigns = error?.response?.data?.medianUserCampaigns ?? null;
        });
    },
  },
};
</script>

<style></style>
